import './App.css';
import Header from './Component/Header'

import Home from './Component/Home'

function App() {
  return (
    <div >
     
     <Header/>
     <Home/>
    </div>
  );
}

export default App;
